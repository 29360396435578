export const requestOffer = async (payload) => {
  console.info("API: requestOffer", process.env.GATSBY_API_URL);
  const response = await fetch(`${process.env.GATSBY_API_URL}/api/offer`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const requestApplication = async (payload) => {
  console.info("API: requestApplication", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/application`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const requestPhoneContact = async ({ requestId }) => {
  console.info("API: requestPhoneContact", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/offer/${requestId}/phone-contact`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const requestMailComparison = async ({ requestId }) => {
  console.info("API: requestMailComparison", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/offer/${requestId}/send-mail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const applicationStarted = async ({ requestId, resultId }) => {
  console.info("API: requestMailComparison", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/offer/${requestId}/application-started`,
    {
      method: "POST",
      body: JSON.stringify({ resultId }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const fetchOfferRequest = async ({ requestId }) => {
  console.info("API: fetchCalculationResult", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/offer/${requestId}`,
    {
      method: "GET",
    }
  );
  return response;
};

export const fetchOffer = async ({ requestId }) => {
  console.info("API: fetchCalculationResult", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/offer/${requestId}/results`,
    {
      method: "GET",
    }
  );
  return response;
};

export const fetchCompanyInfo = async ({ nip }) => {
  console.info("API: fetchCompanyInfo", process.env.GATSBY_API_URL);
  const response = await fetch(
    `${process.env.GATSBY_API_URL}/api/company?nip=${nip}`,
    {
      method: "GET",
    }
  );
  return response;
};

export function timeoutOnFetch( promise, timeout = 5000) {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            reject(new Error("timeout"))
        }, timeout)
        promise.then(resolve, reject)
    })
}
