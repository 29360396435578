import React from "react";
import bruco from "../../images/Obraz1.png";

const Error = ({ children }) => (
  <div className="navbar has-background-dark is-fixed-top center is-size-4 has-text-danger">
    <div className="has-text-centered">
      <br />
      <img src={bruco} alt="bruco" />
      {children}
      <br />
    </div>
  </div>
);

export const ServerError = () => (
  <Error>
    <h2 className="is-size-2 has-text-weight-bold">
      Serwer jest teraz niedostępny.
    </h2>
    <h2>
      {" "}
      Przepraszamy za niedogodności - wrócimy gdy tylko będzie to możliwe!
    </h2>
    <h2>
      W między czasie, po więcej informacji zapraszamy na:
      <a href="https://leasingpoint.pl/">leasingpoint.pl</a>
    </h2>
  </Error>
);

export const NoRequestIdError = () => (
  <Error>
    <h2 className="is-size-2 has-text-weight-bold">
      Zapytanie nie istnieje lub minęło 48h od jego powstania.
    </h2>
  </Error>
);
